import { Box, Skeleton } from "@mantine/core";
import dynamic from "next/dynamic";

const DynamicLottieComponent = dynamic(
    () => import("../components/LottieComponent"),
    {
        loading: () => <Skeleton height={50} circle mb="0" />,
    }
);

export const get_lottie = (
    datakey,
    width = 50,
    top = 0,
    left = -10,
    position = "absolute"
) => {
    return (
        <DynamicLottieComponent {...{ datakey, width, top, left, position }} />
    );
};
export const get_lottie_obj = ({
    datakey,
    width = 50,
    top = 0,
    left = -10,
    position = "absolute",
    loop = true,
}) => {
    return (
        <DynamicLottieComponent
            {...{ datakey, width, top, left, position, loop }}
        />
    );
};

export const ordinal_suffix_of = (i, suffixonly = false) => {
    let j = i % 10,
        k = i % 100;
    let suffix = "th";
    if (j == 1 && k != 11) {
        suffix = "st";
    }
    if (j == 2 && k != 12) {
        suffix = "nd";
    }
    if (j == 3 && k != 13) {
        suffix = "rd";
    }
    if (suffixonly) {
        return suffix;
    }

    return i + suffix;
};
