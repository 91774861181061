import {
    Text,
    Title,
    Container,
    SimpleGrid,
    useMantineTheme,
    createStyles,
    Box,
    Anchor,
    Flex,
    Collapse,
    Button,
} from "@mantine/core";
import {
    IconCalendar,
    IconClock,
    IconBrandGoogleAnalytics,
    IconChevronsUp,
    IconDeviceMobile,
    IconBrain,
    IconArrowRight,
    IconChevronDown,
} from "@tabler/icons";
import Link from "next/link";

import Dots from "./Dots";
import { get_lottie } from "../common/utils";
import { useDisclosure } from "@mantine/hooks";
import OurServices from "./OurServices";

export const MOCKDATA = [
    {
        icon: IconBrain,
        title: "AI Ace: Doubts Defeated",
        description:
            "We provide cutting-edge AI technology to help you solve your doubts and queries in real-time. With a deep understanding of GATE exams, the AI algorithms are designed to provide accurate and relevant answers to help you ace the exams.",
        url: "/doubts",
        lottie: get_lottie("competition", 150, -25, -30),
    },
    {
        icon: IconCalendar,
        title: "Monthly Live Exams",
        description:
            "Give mock exams every Month!. More mock GATE exams means more practice and the more you practice the better you get. These special tests are conducted on a monthly basis and are designed to help the students to prepare for their final GATE mining exam.",

        lottie: get_lottie("calendar", 60),
    },
    {
        icon: IconClock,
        title: "Time Management",
        description:
            "Time is what we want most, but what we use worst. So we provide detailed analytics helping you to keep track of your timing. We understand it's significance in Highly competitive exams like GATE.",
        lottie: get_lottie("time_management", 50, 5),
    },
    {
        icon: IconBrandGoogleAnalytics,
        title: "Performance Metrics",
        description:
            "It's a must to that you monitor your performance regularly in your GATE preparation. So we are providing detailed analytics to assist you to monitor GATE schedule, goal is to provide a feedback loop where you're constantly thinking about what you've done and how you could be doing better.",
        url: "/testanalytics",
        lottie: get_lottie("performance", 55, 5),
    },
    {
        icon: IconChevronsUp,
        title: "Competition++",
        description:
            "We believe competition is a good thing as it forces us to do our best, so we have added a ranking system for weekly tests, so every week you will be ranked against other serious aspirants. We expect the competitors to push you achieve more.",
        lottie: get_lottie("target", 70),
    },
    {
        icon: IconDeviceMobile,
        title: "Mobile Friendly",
        description:
            "Give your tests using your smart phones!, be it phones or laptops all have complete experience. We are thankful to our talented team of designers and web developers, for building the mobile-friendly responsive design.",
        lottie: get_lottie("mobile", 70),
    },
    // {
    //     icon: IconBrain,
    //     title: "Comprehensive Solutions",
    //     description:
    //         "We provide answers to every single question asked in Gate Mining test series, giving you the confidence to tackle the exam. Crafted by experts, our efficient and effective study materials will help you unlock your full potential.",
    //     url: "/questions/TimeID1674829244015",
    //     lottie: get_lottie("competition", 150, -25, -30),
    // },
];

function Feature({
    icon: Icon,
    title,
    description,
    lottie = null,
    url = null,
}) {
    const theme = useMantineTheme();

    return (
        <Box sx={{ position: "relative" }}>
            {/* <ThemeIcon variant="gradient" size={40} radius={40}>
                <Icon size={30} stroke={1.5} />
            </ThemeIcon> */}
            {/* <Skeleton height={50} circle mb="xl" /> */}
            <Box sx={{ height: 50 }}></Box>
            {!!lottie && lottie}
            <Text
                component="h4"
                style={{ marginTop: theme.spacing.sm, marginBottom: 7 }}
            >
                {title}
            </Text>
            <Text
                component="p"
                size="sm"
                color="dimmed"
                style={{ lineHeight: 1.6 }}
                m={0}
            >
                {description}
            </Text>
            {!!url && (
                <Link
                    style={{ textDecoration: "none" }}
                    href={url}
                    passHref
                    legacyBehavior
                >
                    <Anchor>
                        <Flex gap={10} justify={"end"} align={"center"}>
                            <Text>Try it Now</Text>
                            <IconArrowRight size={18} />
                        </Flex>
                    </Anchor>
                </Link>
            )}
        </Box>
    );
}

const useStyles = createStyles((theme) => ({
    wrapper: {
        paddingTop: theme.spacing.xl * 2,
        paddingBottom: theme.spacing.xl * 4,
    },

    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontWeight: 800,
        marginBottom: theme.spacing.md,
        textAlign: "center",
        fontSize: 40,

        [theme.fn.smallerThan("sm")]: {
            fontSize: 28,
            // textAlign: "left",
        },
    },

    description: {
        textAlign: "center",

        [theme.fn.smallerThan("sm")]: {
            textAlign: "left",
        },
    },
    dots: {
        position: "absolute",

        color:
            theme.colorScheme === "dark"
                ? theme.colors.dark[5]
                : theme.colors.gray[3],
        opacity: 0.5,

        "@media (max-width: 755px)": {
            display: "none",
        },
        zIndex: -1,
    },
}));

export function FeaturesSection({ title, data = MOCKDATA }) {
    const { classes, theme } = useStyles();
    const [opened, { open, close, toggle }] = useDisclosure(false);
    const features = data.map((feature, index) => (
        <Feature {...feature} key={index} />
    ));

    return (
        <Box style={{ position: "relative" }}>
            <Dots className={classes.dots} style={{ left: 30, top: 60 }} />
            {/* <Dots className={classes.dots} style={{ left: 60, top: 0 }} />
            <Dots className={classes.dots} style={{ left: 0, top: 140 }} /> */}
            <Dots className={classes.dots} style={{ right: 30, bottom: 60 }} />

            <Container className={classes.wrapper}>
                <Title className={classes.title} order={3}>
                    {title}
                </Title>

                <Container size={560} p={0}>
                    <Text
                        size="sm"
                        component="p"
                        className={classes.description}
                    >
                        Mining test series(MTS) can help you do just that! With
                        our comprehensive GATE exam test series, you can get the
                        edge over your competitors and drastically improve your
                        performance in the GATE exam.
                    </Text>
                </Container>

                <Flex align={"center"} direction={"column"}>
                    {!opened && (
                        <Button
                            radius={"xl"}
                            onClick={toggle}
                            variant="default"
                            rightIcon={<IconChevronDown />}
                        >
                            <Text c="dimmed">Try our services</Text>
                        </Button>
                    )}
                    <Collapse in={opened}>
                        <OurServices />
                    </Collapse>
                </Flex>

                <SimpleGrid
                    mt={60}
                    cols={3}
                    spacing={theme.spacing.xl * 2}
                    breakpoints={[
                        { maxWidth: 980, cols: 2, spacing: "xl" },
                        { maxWidth: 755, cols: 1, spacing: "xl" },
                    ]}
                >
                    {features}
                </SimpleGrid>
            </Container>
        </Box>
    );
}
