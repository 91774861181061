import {
    createStyles,
    // Image,
    Accordion,
    Grid,
    Container,
    Title,
    Text,
    Box,
} from "@mantine/core";
import Image from "next/image";
import image from "../assets/imageFaq.svg";

const useStyles = createStyles((theme) => ({
    wrapper: {
        paddingTop: theme.spacing.xl * 2,
        paddingBottom: theme.spacing.xl * 2,
    },

    title: {
        marginBottom: theme.spacing.md,
        paddingLeft: theme.spacing.md,
        color: theme.colorScheme === "dark" ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    },

    item: {
        fontSize: theme.fontSizes.sm,
        color:
            theme.colorScheme === "dark"
                ? theme.colors.dark[1]
                : theme.colors.gray[7],
    },
}));

const placeholder =
    "It can't help but hear a pin drop from over half a mile away, so it lives deep in the mountains where there aren't many people or Pokémon.";

const data = [
    {
        value: "How-it-helps",
        question:
            "How does the test series help students prepare for the GATE exam?",
        answer: "The GATE exam is one of the most competitive exams in India and requires a lot of preparation and hard work. The test series are designed to help students prepare for the GATE exam by providing them with an opportunity to practice their skills in a simulated environment. Through these test series, students can get an idea of what kind of questions they will face during the actual exam and can also assess their own performance. It helps them to identify their strengths and weaknesses, so that they can focus on improving those areas before appearing for the actual GATE exam.",
    },
    {
        question:
            "Is there a time limit for completing each test in the series?",
        answer: "The series of tests you will be taking may have different time limits, depending on the complexity of the questions and how much time you are allowed to spend on each test. It is important to understand the time limit for each test so that you can plan your study and practice accordingly. Knowing how much time is available for each test will also help you in managing your stress levels during the exam.",
        value: "time-limit",
    },
    {
        question:
            "How often should students take tests from the mining engineering test series?",
        answer: "Taking tests from the mining engineering test series is an important part of a student's learning process. It helps them to assess their understanding of the subject and identify areas where they need to improve. The frequency of taking tests should be determined based on the student's learning goals and progress. Generally, it is recommended that students take tests from the mining engineering test series at least once every two weeks, or more often if needed. This will help them to stay on track with their studies and ensure that they are prepared for any upcoming exams.",
        value: "frequency",
    },
    {
        question: "How do I prepare for an online exam in mining test series?",
        answer: "Taking an online exam can be a daunting task, especially if you are not familiar with the test series. To ensure that you are well-prepared to tackle the online exam in mining test series, it is important to understand the format and structure of the exam. This article will provide tips on how to prepare for an online exam in mining test series. It will discuss topics such as creating a study plan, understanding the syllabus, researching resources, and practicing mock tests. With these tips, you can be confident that you have done your best to prepare for success on your upcoming online exams.",
        value: "preparation",
    },
];

export function Faq() {
    const { classes } = useStyles();
    const items = data.map(({ question, answer, value }, idx) => {
        return (
            <Accordion.Item
                key={"faq_" + idx}
                className={classes.item}
                value={value}
            >
                <Accordion.Control>
                    <Title order={4}>{question}</Title>
                </Accordion.Control>
                <Accordion.Panel>
                    <Text component="p">{answer}</Text>
                </Accordion.Panel>
            </Accordion.Item>
        );
    });
    return (
        <Box className={classes.wrapper}>
            <Container size="lg">
                <Grid id="faq-grid">
                    <Grid.Col span={12} md={6}>
                        <Box
                            sx={{
                                position: "relative",
                                width: "100%",
                                height: "100%",
                                minHeight: 300,
                            }}
                        >
                            <Image
                                fill
                                src={image.src}
                                alt="Frequently Asked Questions"
                            />
                        </Box>
                    </Grid.Col>
                    <Grid.Col span={12} md={6}>
                        <Title order={3} align="left" className={classes.title}>
                            Frequently Asked Questions
                        </Title>

                        <Accordion
                            chevronPosition="right"
                            defaultValue="How-it-helps"
                            variant="separated"
                        >
                            {...items}
                        </Accordion>
                    </Grid.Col>
                </Grid>
            </Container>
        </Box>
    );
}
