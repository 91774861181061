import {
    Box,
    Button,
    createStyles,
    Flex,
    Image,
    keyframes,
    MediaQuery,
    Text,
} from "@mantine/core";
import { IconArrowRight, IconCheck } from "@tabler/icons";
import Link from "next/link";
import { useRouter } from "next/router";
import itsfree from "../assets/free.svg";
import { useState } from "react";

const spin = keyframes({
    "0%": {
        "--rotate": "0deg",
    },
    "100%": {
        "--rotate": "360deg",
    },
});

const moveRight = keyframes({
    "0%": {
        transform: `translateX(0)`,
    },
    "100%": {
        transform: `translateX(10px)`,
    },
});

const useStyles = createStyles((theme) => ({
    box: {
        padding: "3px",
        borderRadius: theme.radius.md,
        backgroundImage: `linear-gradient(var(--rotate), #099268, #80ed99 43%, #66a80f )`,
        animation: `${spin} 5s linear infinite`,
        transition: `transform 0.4s`,
        "&:hover": {
            transform: "scale(1.02)",
            // animation: "none",
            // backgroundImage: `linear-gradient(var(--rotate), #099268, #66a80f )`,
        },
    },
    icon: {
        animation: `${moveRight} 0.5s linear alternate infinite`,
    },
}));

const GetStartedButton = () => {
    const { classes } = useStyles();
    const router = useRouter();
    const [loading, setLoading] = useState(false);

    return (
        <Box className={classes.box}>
            {/* <Link href={"/auth/new-user"}> */}
            <Button
                size="lg"
                // variant="gradient"
                // gradient={{ from: "teal", to: "lime", deg: 105 }}
                color={"green"}
                classNames={{ rightIcon: !loading ? classes.icon : null }}
                rightIcon={<IconArrowRight color="black" />}
                onClick={() => {
                    setLoading(true);
                    router.push("/dashboard");
                }}
                loading={loading}
                loaderPosition="right"
            >
                <MediaQuery smallerThan={"md"} styles={{ display: "none" }}>
                    <Flex>
                        <Text c="dark.9" fw={"bold"} fz={"xl"}>
                            Start Your Preparation Today
                        </Text>
                    </Flex>
                </MediaQuery>
                <MediaQuery largerThan={"md"} styles={{ display: "none" }}>
                    <Text c="dark.9" fw={"bold"} fz={"xl"}>
                        Get Started Today
                    </Text>
                </MediaQuery>
            </Button>
            {/* </Link> */}
        </Box>
    );
};

export default GetStartedButton;
