import {
    createStyles,
    Container,
    Title,
    Button,
    Group,
    Text,
    List,
    ThemeIcon,
    Box,
    MediaQuery,
    Flex,
} from "@mantine/core";
import { IconArrowRight, IconCheck } from "@tabler/icons";
import itsfree from "../assets/free.svg";
import Link from "next/link";

import GetStartedButton from "./GetStartedButton";
import Script from "next/script";
import Tilter from "./Tilter";
import TitleImage from "./Tilter";
import Image from "next/image";
import { SocialButton } from "@mantine/ds";
import SocialConnect from "./SocialConnect";

const nextYear = new Date().getFullYear() + 1;

const useStyles = createStyles((theme) => ({
    bg: {
        background:
            theme.colorScheme == "light"
                ? 'linear-gradient(180deg, rgba(255,255,255,1) 35%, rgba(255,255,255,0.9) 100%) ,url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoAQMAAAC2MCouAAAABlBMVEWBgYFHcEyMaNnGAAAAAnRSTlP/AOW3MEoAAAAQSURBVHgBY4CA+v9AMBJIALryrr41QoYVAAAAAElFTkSuQmCC")'
                : 'linear-gradient(180deg, rgba(18,18,18,1) 35%, rgba(18,18,18,0.9) 100%) ,url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoAQMAAAC2MCouAAAABlBMVEWBgYFHcEyMaNnGAAAAAnRSTlP/AOW3MEoAAAAQSURBVHgBY4CA+v9AMBJIALryrr41QoYVAAAAAElFTkSuQmCC")',
        // backgroundImage: "url(/miningbg.svg)",
        // backgroundRepeat: "no-repeat",
        // backgroundPositionX: "right",
        // backgroundPositionY: "bottom",
        // backgroundSize: "800px ",
    },
    bg2: {
        [`@media (min-width: ${theme.breakpoints.md}px)`]: {
            background: "url(/miningbg.svg)",
            backgroundRepeat: "no-repeat",
            backgroundPositionX: "right",
            backgroundPositionY: "bottom",
            backgroundSize: "70vw",
        },
    },
    inner: {
        display: "flex",
        justifyContent: "space-between",
        paddingTop: theme.spacing.xl * 4,
        paddingBottom: theme.spacing.xl * 4,
    },

    content: {
        maxWidth: 600,
        marginRight: theme.spacing.xl * 3,

        [theme.fn.smallerThan("md")]: {
            maxWidth: "100%",
            marginRight: 0,
        },
    },

    title: {
        color: theme.colorScheme === "dark" ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: 44,
        lineHeight: 1.2,
        fontWeight: 900,

        [theme.fn.smallerThan("xs")]: {
            fontSize: 28,
        },
    },

    control: {
        [theme.fn.smallerThan("xs")]: {
            flex: 1,
        },
    },

    image: {
        flex: 1,

        [theme.fn.smallerThan("md")]: {
            display: "none",
        },

        maxWidth: 400,
        maxHeight: 400,
    },

    highlight: {
        position: "relative",
        backgroundColor: theme.fn.variant({
            variant: "light",
            color: theme.primaryColor,
        }).background,
        borderRadius: theme.radius.sm,
        padding: "4px 12px",
    },
}));

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function HeroTitle({
    branch = "mining",
    exam = "gate",
    company_short = "MTS",
}) {
    const { classes } = useStyles();
    const branch_cap = capitalizeFirstLetter(branch);
    const exam_cap = capitalizeFirstLetter(exam);
    const exam_upper = exam.toUpperCase();

    return (
        <Box className={classes.bg}>
            <Box className={classes.bg2}>
                <Container>
                    <Box className={classes.inner}>
                        <Box className={classes.content}>
                            <Title className={classes.title}>
                                India&apos;s{" "}
                                <span className={classes.highlight}>
                                    <Text
                                        component="span"
                                        inherit
                                        variant="gradient"
                                        gradient={{
                                            from: "pink",
                                            to: "yellow",
                                        }}
                                    >
                                        #1
                                    </Text>
                                </span>{" "}
                                Test series <br />
                                for{" "}
                                <Text
                                    component="span"
                                    inherit
                                    variant="gradient"
                                    gradient={{ from: "pink", to: "yellow" }}
                                >
                                    {exam_cap} {branch_cap}
                                </Text>{" "}
                                aspirants
                            </Title>
                            <MediaQuery
                                smallerThan={"sm"}
                                styles={(theme) => ({
                                    fontSize: theme.fontSizes.md,
                                })}
                            >
                                <Title
                                    order={2}
                                    sx={{ fontWeight: "normal" }}
                                    color="dimmed"
                                    mt="md"
                                    component="h3"
                                >
                                    {company_short} is India&apos;s top provider
                                    for {branch_cap} {exam_upper} exam test
                                    series, with thousands of satisfied{" "}
                                    {exam_upper} candidates and {branch_cap}{" "}
                                    engineers. Join us for {exam_upper}{" "}
                                    {nextYear} preparation.
                                </Title>
                            </MediaQuery>

                            {/* <List
                                mt={30}
                                spacing="sm"
                                size="sm"
                                icon={
                                    <ThemeIcon
                                        size={20}
                                        radius="xl"
                                        color={"green"}
                                    >
                                        <IconCheck size={12} stroke={1.5} />
                                    </ThemeIcon>
                                }
                            >
                                <List.Item>
                                    <b>Free for everyone</b> - Unlock your full
                                    potential and pass the {exam_upper} exam
                                    with our free online test series!.
                                </List.Item>
                                <List.Item>
                                    <b>Highly Competitive</b> - Unlock your full
                                    potential and rise to the top with our
                                    competitive test series that motivates you
                                    to outperform the rest.
                                </List.Item>

                                <List.Item>
                                    <b>Mobile Friendly</b> - Take your tests on
                                    the go with our mobile-friendly platform.
                                </List.Item>
                            </List> */}

                            <Flex
                                mt={30}
                                justify={"center"}
                                gap={0}
                                sx={{ position: "relative" }}
                            >
                                <MediaQuery
                                    smallerThan={"xs"}
                                    styles={{ display: "none" }}
                                >
                                    <Image
                                        alt="free"
                                        src={itsfree.src}
                                        priority={true}
                                        width={120}
                                        height={45}
                                        style={{
                                            position: "relative",
                                            top: 25,
                                        }}
                                    />
                                </MediaQuery>

                                <GetStartedButton />

                                {/* <Button
                                variant="default"
                                radius="xl"
                                size="md"
                                className={classes.control}
                            >
                                Telegram
                            </Button> */}
                            </Flex>
                            <Flex mt={30} justify={"center"}>
                                <SocialConnect />
                            </Flex>
                            <Flex mt={30} align={"center"} direction={"column"}>
                                <Text>
                                    <Text span fz={30} fw={"bold"}>
                                        238
                                    </Text>{" "}
                                    <Text span fz={30} fw={"bold"}>
                                        +
                                    </Text>
                                </Text>
                                <Text>GATE aspirants already joined us</Text>
                                <Text c={"dimmed"}>
                                    we will update this count daily
                                </Text>
                            </Flex>
                        </Box>
                        {/* <TitleImage /> */}
                    </Box>
                </Container>
            </Box>
        </Box>
    );
}
