// import { Button } from "ui2";
import { Box, Divider } from "@mantine/core";
import { HeroTitle } from "../../../packages/ui2/components/HeroTitle";
import { FeaturesSection } from "../../../packages/ui2/components/FeaturesSection";
import { ReviewsSection } from "../../../packages/ui2/components/ReviewsSection";
import { Faq } from "../../../packages/ui2/components/Faq";

import { get_seo } from "../seo/seoCommon";
import Script from "next/script";

export default function Home() {
    // dynamically load the component if the scroll position is reaching
    // this will mess up seo so dont implement it

    return (
        <Box>
            {get_seo("index")}

            <HeroTitle />
            <Divider />
            <FeaturesSection title={"Ace Your Exam with Us!"} />

            <ReviewsSection />
            {/* <Box
                sx={{
                    backgroundImage: 'url("/wavesblueblack.svg")',
                    // height: 200,

                    aspectRatio: "900/200",
                    position: "relative",
                    top: -90,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                }}
            ></Box> */}
            <Faq />
        </Box>
    );
}
