import { Box, createStyles, keyframes } from "@mantine/core";
import { useMouse, useViewportSize } from "@mantine/hooks";
import Image from "next/image";
import { useEffect } from "react";
import { nextYear } from "../../../apps/mn/seo/seoCommon";
import image from "../assets/imageexam.svg";

const moveUpDown = keyframes({
    "0%": {
        transform: `translateY(-10px)`,
    },
    "100%": {
        transform: `translateY(10px)`,
    },
});

const useStyles = createStyles((theme) => ({
    image: {
        flex: 1,
        position: "relative",

        [theme.fn.smallerThan("md")]: {
            display: "none",
        },
        transform: `translateY(-10px)`,
        animation: `${moveUpDown} 2s ease-in alternate infinite`,
        animationDelay: "3s",
    },
}));

const TitleImage = () => {
    const { classes } = useStyles();
    // const { x, y } = useMouse();
    // const { height, width } = useViewportSize();

    // const rotatex = 0;
    // const rotatey = (x - width / 2) / 30;

    return (
        <Box
            sx={{
                minHeight: 300,
            }}
            className={classes.image}
        >
            <Image
                priority={true}
                fill
                src={image.src}
                alt={`a student taking MTS Gate test series online for GATE ${nextYear} exam`}

                // style={{
                //     transform: `rotateX(${rotatex}deg) rotateY(${rotatey}deg)`,
                // }}
            />
        </Box>
    );
};

export default TitleImage;
